var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editor-wrapper"},[_c('div',{staticClass:"editor-menubar-wrapper"},[_c('div',{staticClass:"editor-menu-button"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({domProps:{"textContent":_vm._s(_vm.returnActiveHeader)}},'v-icon',attrs,false),on))]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.editor.chain().focus().setParagraph().run();
              _vm.activeHeader = 'mdi-format-paragraph';}}},[_vm._v(" Paragraph "),_c('v-icon',{attrs:{"right":"","small":""},domProps:{"textContent":_vm._s('mdi-format-paragraph')}})],1),_vm._l((6),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: item }).run();
              _vm.activeHeader = "mdi-format-header-" + item;}}},[_vm._v(" "+_vm._s(("Heading " + item))+" "),_c('v-icon',{attrs:{"small":"","right":""},domProps:{"textContent":_vm._s(("mdi-format-header-" + item))}})],1)})],2)],1)],1),_c('div',{staticClass:"editor-menu-button"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({style:(("color: " + (_vm.textColor.hex) + ";")),domProps:{"textContent":_vm._s('mdi-palette')}},'v-icon',attrs,false),on))]}}])},[_c('v-list',[_c('v-list-item',[_c('v-color-picker',{attrs:{"hide-canvas":"","hide-sliders":"","hide-inputs":"","show-swatches":""},model:{value:(_vm.textColor),callback:function ($$v) {_vm.textColor=$$v},expression:"textColor"}})],1)],1)],1)],1),_c('div',{staticClass:"editor-menu-button"},[_c('v-autocomplete',{staticClass:"ma-0 pa-0",attrs:{"dense":"","placeholder":"Font Family","items":_vm.fontsArray,"item-text":"name","item-value":"name"},on:{"change":function($event){_vm.editor.chain().focus('all').setFontFamily(_vm.textFont).run()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.split(",")[0])+" ")]}},{key:"item",fn:function(data){return [_c('v-list-item-content',{style:(("font-family: " + (data.item))),domProps:{"textContent":_vm._s(data.item.split(',')[0])}})]}}]),model:{value:(_vm.textFont),callback:function ($$v) {_vm.textFont=$$v},expression:"textFont"}})],1),_vm._l((_vm.textFormatButtons),function(item,i){return _c('div',{key:i,class:{
        'is-active': _vm.editor.isActive(item.name)
          ? _vm.editor.isActive(item.name)
          : '',
        'editor-menu-button': 'editor-menu-button',
      }},[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)},on:{"click":item.action}})],1)}),_c('div',{staticClass:"editor-menu-button"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({domProps:{"textContent":_vm._s(_vm.returnActiveAlignment)}},'v-icon',attrs,false),on))]}}])},[_c('v-list',_vm._l((['left', 'center', 'justify']),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){_vm.editor.chain().focus().setTextAlign(item).run();
              _vm.activeAlignment = "mdi-format-align-" + item;}}},[_c('v-icon',{domProps:{"textContent":_vm._s(("mdi-format-align-" + item))}})],1)}),1)],1)],1),_c('div',{staticClass:"editor-menu-button",on:{"click":function($event){_vm.editor.chain().focus().setHorizontalRule().run()}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-format-page-break')}})],1),_c('div',{class:{
        'is-active': _vm.editor.isActive('highlight')
          ? _vm.editor.isActive('highlight')
          : '',
        'editor-menu-button': 'editor-menu-button',
      }},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({domProps:{"textContent":_vm._s('mdi-format-color-highlight')}},'v-icon',attrs,false),on))]}}])},[_c('v-list',[_c('v-list-item',[_c('div',{staticClass:"swatch-wrapper"},[(_vm.editor.isActive('highlight'))?_c('v-btn',{attrs:{"text":"","left":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().unsetHighlight().run()}}},[_c('v-icon',{attrs:{"small":"","left":""},domProps:{"textContent":_vm._s('mdi-close')}}),_vm._v(" Remove ")],1):_vm._e(),_vm._l((_vm.highlightSwatches),function(item){return _c('div',{key:item,staticClass:"swatch",style:({ 'background-color': item }),attrs:{"small":""},on:{"click":function($event){_vm.editor.chain().focus().setHighlight({ color: item }).run()}}})})],2)])],1)],1)],1),_vm._l((_vm.historyButtons),function(item){return _c('div',{key:item.name,class:{
        'is-active': _vm.editor.isActive(item.name)
          ? _vm.editor.isActive(item.name)
          : '',
        'editor-menu-button': 'editor-menu-button',
      }},[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)},on:{"click":item.action}})],1)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:{
            'is-active': _vm.toggleHtmlOutput,
            'editor-menu-button': 'editor-menu-button',
          }},'div',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-code-tags')},on:{"click":function($event){_vm.toggleHtmlOutput = !_vm.toggleHtmlOutput}}})],1)]}}])},[_c('span',[_vm._v("View HTML output")])])],2),_c('bubble-menu',{attrs:{"editor":_vm.editor}},[_c('v-card',[(!_vm.editor.isActive('link'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.anchorModal = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-link-variant-plus')}})],1)]}}],null,false,2475186035)},[_c('span',[_vm._v("Insert link")])]):(_vm.editor.isActive('link'))?_vm._l((_vm.linkButtons),function(item){return _c('v-tooltip',{key:item.name,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":item.action}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tooltip))])])}):_vm._e()],2)],1),(!_vm.toggleHtmlOutput)?_c('editor-content',{staticClass:"editor-content",attrs:{"editor":_vm.editor}}):_c('textarea',{staticClass:"editor-content html-output",attrs:{"readonly":""},domProps:{"value":_vm.value}}),_vm._v(" "),_c('v-dialog',{attrs:{"width":"500px","persistent":""},model:{value:(_vm.anchorModal),callback:function ($$v) {_vm.anchorModal=$$v},expression:"anchorModal"}},[_c('v-card',[_c('v-container',{staticClass:"pa-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"mb-3"},[_vm._v("Insert Link")]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.validationError)?_c('v-alert',{attrs:{"type":"error","text":"","dense":""}},[_vm._v("Testing")]):_vm._e(),_c('v-text-field',{attrs:{"outlined":"","dense":"","append-icon":"mdi-link-variant","hint":"http:// is required for links to external pages","persistent-hint":"","rules":_vm.urlRule},model:{value:(_vm.anchorHref),callback:function ($$v) {_vm.anchorHref=$$v},expression:"anchorHref"}}),_c('v-checkbox',{attrs:{"label":"Open in new tab"},model:{value:(_vm.anchorNewTab),callback:function ($$v) {_vm.anchorNewTab=$$v},expression:"anchorNewTab"}})],1),_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.insertLink()}}},[_vm._v(" Insert Link ")]),_c('v-btn',{staticClass:"float-right",attrs:{"text":""},on:{"click":function($event){return _vm.cancelInsertLink()}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-close")]),_vm._v("Cancel")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }