<template>
  <div class="editor-wrapper">
    <!--Menu-->
    <div class="editor-menubar-wrapper">
      <!--Headers-->
      <div class="editor-menu-button">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" v-text="returnActiveHeader" />
          </template>
          <v-list>
            <v-list-item
              @click="
                editor.chain().focus().setParagraph().run();
                activeHeader = 'mdi-format-paragraph';
              "
            >
              Paragraph
              <v-icon right small v-text="'mdi-format-paragraph'" />
            </v-list-item>
            <v-list-item
              v-for="item in 6"
              :key="item"
              @click="
                editor.chain().focus().toggleHeading({ level: item }).run();
                activeHeader = `mdi-format-header-${item}`;
              "
            >
              {{ `Heading ${item}` }}
              <v-icon small right v-text="`mdi-format-header-${item}`" />
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <!--Text Colour-->
      <div class="editor-menu-button">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-text="'mdi-palette'"
              :style="`color: ${textColor.hex};`"
            />
          </template>
          <v-list>
            <v-list-item>
              <v-color-picker
                v-model="textColor"
                hide-canvas
                hide-sliders
                hide-inputs
                show-swatches
              />
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <!--Font Family-->
      <div class="editor-menu-button">
        <v-autocomplete
          class="ma-0 pa-0"
          dense
          placeholder="Font Family"
          v-model="textFont"
          :items="fontsArray"
          item-text="name"
          item-value="name"
          @change="editor.chain().focus('all').setFontFamily(textFont).run()"
        >
          <template v-slot:selection="data">
            {{ data.item.split(",")[0] }}
          </template>
          <template v-slot:item="data">
            <v-list-item-content
              v-text="data.item.split(',')[0]"
              :style="`font-family: ${data.item}`"
            ></v-list-item-content>
          </template>
        </v-autocomplete>
      </div>
      <!--Text Formating-->
      <div
        v-for="(item, i) in textFormatButtons"
        :key="i"
        :class="{
          'is-active': editor.isActive(item.name)
            ? editor.isActive(item.name)
            : '',
          'editor-menu-button': 'editor-menu-button',
        }"
      >
        <v-icon @click="item.action" v-text="item.icon" />
      </div>
      <!--Text Alignment-->
      <div class="editor-menu-button">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" v-text="returnActiveAlignment" />
          </template>
          <v-list>
            <!--
                  There seems to be an issue with 'right alignment
                  It has been removed for now
              -->
            <v-list-item
              v-for="item in ['left', 'center', 'justify']"
              :key="item"
              @click="
                editor.chain().focus().setTextAlign(item).run();
                activeAlignment = `mdi-format-align-${item}`;
              "
            >
              <v-icon v-text="`mdi-format-align-${item}`" />
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div
        class="editor-menu-button"
        @click="editor.chain().focus().setHorizontalRule().run()"
      >
        <v-icon v-text="'mdi-format-page-break'" />
      </div>
      <!--Text Highlighting-->
      <div
        :class="{
          'is-active': editor.isActive('highlight')
            ? editor.isActive('highlight')
            : '',
          'editor-menu-button': 'editor-menu-button',
        }"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-text="'mdi-format-color-highlight'"
            />
          </template>
          <v-list>
            <!--
                  There seems to be an issue with 'right alignment
                  It has been removed for now
              -->
            <v-list-item>
              <div class="swatch-wrapper">
                <v-btn
                  text
                  left
                  small
                  v-if="editor.isActive('highlight')"
                  @click="editor.chain().focus().unsetHighlight().run()"
                >
                  <v-icon small left v-text="'mdi-close'" />
                  Remove
                </v-btn>
                <div
                  class="swatch"
                  @click="
                    editor.chain().focus().setHighlight({ color: item }).run()
                  "
                  small
                  v-for="item in highlightSwatches"
                  :key="item"
                  :style="{ 'background-color': item }"
                ></div>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <!--History-->
      <div
        v-for="item in historyButtons"
        :key="item.name"
        :class="{
          'is-active': editor.isActive(item.name)
            ? editor.isActive(item.name)
            : '',
          'editor-menu-button': 'editor-menu-button',
        }"
      >
        <v-icon @click="item.action" v-text="item.icon" />
      </div>
      <!--View HTML Output-->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            :class="{
              'is-active': toggleHtmlOutput,
              'editor-menu-button': 'editor-menu-button',
            }"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              v-text="'mdi-code-tags'"
              @click="toggleHtmlOutput = !toggleHtmlOutput"
            />
          </div>
        </template>
        <span>View HTML output</span>
      </v-tooltip>
    </div>
    <!--Bubble menu-->
    <bubble-menu :editor="editor">
      <v-card>
        <!--Add link-->
        <v-tooltip v-if="!editor.isActive('link')" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="anchorModal = true"
              ><v-icon small v-text="'mdi-link-variant-plus'" />
            </v-btn>
          </template>
          <span>Insert link</span>
        </v-tooltip>
        <!--Remove Link-->
        <v-tooltip
          v-for="item in linkButtons"
          :key="item.name"
          bottom
          v-else-if="editor.isActive('link')"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="item.action"
              ><v-icon small>{{ item.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.tooltip }}</span>
        </v-tooltip>
      </v-card>
    </bubble-menu>
    <!--Content-->
    <editor-content
      class="editor-content"
      :editor="editor"
      v-if="!toggleHtmlOutput"
    />
    <!--HTML Output-->
    <textarea
      class="editor-content html-output"
      :value="value"
      readonly
      v-else
    />
    <!--Anchor Text URL-->
    <v-dialog v-model="anchorModal" width="500px" persistent>
      <v-card>
        <v-container class="pa-5">
          <v-row>
            <v-col cols="12">
              <h3 class="mb-3">Insert Link</h3>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-alert v-if="validationError" type="error" text dense
                  >Testing</v-alert
                >
                <v-text-field
                  v-model="anchorHref"
                  outlined
                  dense
                  append-icon="mdi-link-variant"
                  hint="http:// is required for links to external pages"
                  persistent-hint
                  :rules="urlRule"
                ></v-text-field>
                <v-checkbox
                  v-model="anchorNewTab"
                  label="Open in new tab"
                ></v-checkbox>
              </v-form>
              <v-btn @click="insertLink()" color="primary" class="float-right">
                Insert Link
              </v-btn>
              <v-btn text @click="cancelInsertLink()" class="float-right"
                ><v-icon left small>mdi-close</v-icon>Cancel</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import { BulletList } from "@tiptap/extension-bullet-list";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Underline } from "@tiptap/extension-underline";
import { Heading } from "@tiptap/extension-heading";
import { Link } from "@tiptap/extension-link";
import { Paragraph } from "@tiptap/extension-paragraph";
import { TextAlign } from "@tiptap/extension-text-align";
import { Highlight } from "@tiptap/extension-highlight";
import { TextStyle } from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import { FontFamily } from "@tiptap/extension-font-family";
import { HorizontalRule } from "@tiptap/extension-horizontal-rule";

export default {
  components: {
    EditorContent,
    BubbleMenu,
  },

  props: {
    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      editor: null,
      anchorModal: false,
      anchorHref: null,
      anchorNewTab: true,
      valid: false,
      validationError: false,
      activeHeader: null,
      activeAlignment: null,
      toggleHtmlOutput: false,
      textColor: { hex: null },
      highlightSwatches: [
        "#fed24f",
        "#f97172",
        "#fdaf59",
        "#9b71fc",
        "#51cafc",
        "#30d4c0",
      ],
      textSwatches: [],
      textFont: null,
      fontsArray: [
        "Arial, sans-serif",
        "Brush Script MT, cursive",
        "Courier New, monospace",
        "Garamond, serif",
        "Georgia, serif",
        "Helvetica, sans-serif",
        "Tahoma, sans-serif",
        "Times New Roman, serif",
        "Trebuchet MS, sans-serif",
        "Verdana, sans-serif",
      ],
      urlRule: [
        (v) => !!v || "You must include a URL",
        (v) => {
          const substrings = ["http://", "https://", "mailto:", "tel:"];
          return (
            // Check if string "v" includes a substring contained in array
            substrings.some((el) => v.includes(el)) ||
            "The URL must start with 'http://' or 'https://'"
          );
        },
      ],
      textFormatButtons: [
        {
          name: "bold",
          icon: "mdi-format-bold",
          action: () => {
            this.editor.chain().focus().toggleBold().run();
          },
        },
        {
          name: "italic",
          icon: "mdi-format-italic",
          action: () => {
            this.editor.chain().focus().toggleItalic().run();
          },
        },
        {
          name: "underline",
          icon: "mdi-format-underline",
          action: () => {
            this.editor.chain().focus().toggleUnderline().run();
          },
        },
        {
          name: "strike",
          icon: "mdi-format-strikethrough",
          action: () => {
            this.editor.chain().focus().toggleStrike().run();
          },
        },
        {
          name: "bulletList",
          icon: "mdi-format-list-bulleted",
          action: () => {
            this.editor.chain().focus().toggleBulletList().run();
          },
        },
        {
          name: "orderedList",
          icon: "mdi-format-list-numbered",
          action: () => {
            this.editor.chain().focus().toggleOrderedList().run();
          },
        },
      ],
      historyButtons: [
        {
          name: "undo",
          icon: "mdi-undo-variant",
          action: () => {
            this.editor.chain().focus().undo().run();
          },
        },
        {
          name: "redo",
          icon: "mdi-redo-variant",
          action: () => {
            this.editor.chain().focus().redo().run();
          },
        },
      ],
      //
      linkButtons: [
        {
          name: "view",
          tooltip: "Preview link",
          icon: "mdi-eye",
          action: () => {
            window.open(this.editor.getAttributes("link").href, "_blank");
          },
        },
        {
          name: "edit",
          tooltip: "Edit link",
          icon: "mdi-pencil",
          action: () => {
            this.anchorHref = this.editor.getAttributes("link").href;
            this.anchorModal = true;
          },
        },
        {
          name: "delete",
          tooltip: "Remove link",
          icon: "mdi-link-variant-off",
          action: () => this.editor.chain().focus().unsetLink().run(),
        },
      ],
    };
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
    // Change text color based on color picker selection
    textColor(value) {
      this.editor.chain().focus().setColor(value.hex).run();
    },
  },
  computed: {
    returnActiveHeader() {
      return this.activeHeader ? this.activeHeader : "mdi-format-header-1";
    },
    returnActiveAlignment() {
      return this.activeAlignment
        ? this.activeAlignment
        : "mdi-format-align-left";
    },
  },
  methods: {
    // Add URL to description
    insertLink() {
      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        this.validationError = true;
        return;
      }
      this.anchorModal = false;
      this.editor
        .chain()
        .focus()
        .setLink({
          href: this.anchorHref,
          target: this.anchorNewTab ? "_blank" : "_self",
        })
        .run();
      this.anchorHref = null;
      this.anchorNewTab = true;
      this.validationError = false;
    },
    //
    cancelInsertLink() {
      this.anchorModal = false;
      this.anchorHref = null;
      this.anchorNewTab = true;
      this.validationError = false;
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
        Highlight.configure({
          multicolor: true,
        }),
        BulletList,
        OrderedList,
        Paragraph,
        Heading,
        TextStyle,
        HorizontalRule.configure({
          HTMLAttributes: {
            class: "custom-horizontal-rule",
          },
        }),
        TextAlign.configure({
          types: ["heading", "paragraph"],
          defaultAlignment: "right",
        }),
        Color.configure({
          types: ["textStyle"],
        }),
        FontFamily.configure({
          types: ["textStyle"],
        }),
        Link.configure({
          openOnClick: false,
          HTMLAttributes: {
            class: "tiptap-link",
          },
        }),
      ],
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML());

        // JSON
        //this.$emit("input", this.editor.getJSON());
      },
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>
<style scoped>
.editor-wrapper {
  border: 2px solid #ccc;
  border-radius: 5px;
}
.editor-menubar-wrapper {
  display: flex;
  max-width: 100%;
  width: 100%;
  margin-bottom: 1em;
  border-bottom: 1px solid #ccc;
}
.editor-menu-button {
  padding: 0.3em;
}
/deep/ .ProseMirror {
  padding: 0.1em 1em;
  line-height: 0.9em;
  min-height: 100px;
}
/deep/ .ProseMirror h1,
/deep/ .ProseMirror h2,
/deep/ .ProseMirror h3,
/deep/ .ProseMirror h4,
/deep/ .ProseMirror h5,
/deep/ .ProseMirror h6 {
  margin-bottom: 1em;
}
/deep/ .ProseMirror:focus,
.html-output:focus {
  outline: none;
}
.is-active {
  background-color: #ddd;
  outline: 0.5px solid #ccc;
}
.swatch-wrapper {
  display: flex;
  width: 100%;
  max-width: 100%;
}
.swatch {
  flex-direction: row;
  margin-right: 5px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
.swatch:hover {
  cursor: pointer;
  box-shadow: #ccc 3px 3px 5px;
  outline: #ccc;
}
.html-output {
  width: 100%;
  max-width: 100%;
  padding: 0 1em;
  min-height: 100px;
}
</style>